import * as React from 'react'
import NavHeader from '../../components/navHeader'
import SiteFooter from '../../components/footer'
import PageTitle from '../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <div><Link to="../../eGovernment"></Link></div>
                            <h1>Jednotný platobný systém</h1>
                            
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Východiská</h2>
                            <p>Tradične sa na Slovensku za služby štátu platilo papierovými kolkami na pošte v ročnom
                                objeme
                                okolo 200 mil. EUR. Presné číslo nebolo možné určiť. Tento spôsob bol pre občanov
                                komplikovaný, časovo náročný. Pre štát nákladný, neprehľadný, s vysokým výskytom rôznych
                                podvodov. </p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Riešenie</h2>
                            <p>Tento systém bol neudržateľný a vrámci reformy verejnej správy bol nahradený centrálnym
                                informačným systémom, ktorý zabezpečuje komplexnú platobnú služby pre občanov a všetky
                                inštitúcie verejnej správy. </p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Výhody riešenia</h2>
                            <h3>Občania:</h3>
                            <ul>
                                <li>Pohodlný spôsob platby za služby verejnej správy cez všetky platobné kanály
                                    a zariadenia
                                    (mobil, pokladňa, kiosk, bankový prevod, hotovosť, karta)</li>
                                <li>Okamžitý prehľad o všetkých platbách štátu</li>
                            </ul>
                            <h3>Ministerstvo financií:</h3>
                            <ul>
                                <li>Okamžitý prehľad o všetkých platbách za jednotlivé služby, do jednotlivých
                                    inštitúcií
                                    a za jednotlivých občanov a podnikateľov</li>
                                <li>Eliminácia väčšiny podvodov</li>
                                <li>Okamžitý prehľad o dopyte po službách verejnej správy a výkonnosti jednotlivých
                                    úradov z
                                    pohľadu platieb za služby</li>
                            </ul>
                            <h3>Úradníci:</h3>
                            <ul>
                                <li>Automatizácia procesov vystavenia príkazu na úhradu a kontroly zaplatenia za službu
                                </li>
                                <li>Pohodlný a spoľahlivý spôsob vystavenia príkazu na úhradu za služby</li>
                                <li>Pohodlný a spoľahlivý spôsob získania informácie o úhrade poplatku za službu</li>
                            </ul>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment